import IFieldData from "@interfaces/IFieldData";

import { toastHandler } from "@utils/toastHandler";
import { getDateDatabase, isDate } from "@utils/date";

export interface IFormProps {
  dataObject?: any;
  onBack?: Function;
  excludeFields?: boolean;
}

export interface IRenderItem {
  key: string;
  label: string;
  value: string;
}

export interface IItem {
  id: string | number;
  name?: string;
  dni?: string;
  rtn?: string;
  type?: string;
  price?: number;
  phone?: string;
  phonePrefix?: string;
  totalNumeration?: number;
  currentNumeration?: number;
  deadlineForIssuance?: string;
}

export interface IItemOption {
  label: string;
  key: string | number;
  value: string | number;
  name?: string;
  dni?: string;
  rtn?: string;
  type?: string;
  price?: number;
  phone?: string;
  phoneRaw?: string;
  phonePrefix?: string;
  totalNumeration?: number;
  currentNumeration?: number;
  deadlineForIssuance?: string;
}

export const onFinishFailed = () => {
  toastHandler.error("Llenar todos los campos requeridos!");
};

export const getFieldData = (fieldData: any): IFieldData[] =>
  Object.entries(fieldData)?.map(([key, value]: any) => ({
    name: key,
    value: isDate(value) ? getDateDatabase(value) : value?.key || value,
  }));

export const filterData = (inputValue: any, option: any) => {
  const labelMatch = option.label
    .toUpperCase()
    .includes(inputValue.toUpperCase());
  const phoneMatch = option.phone
    ? option.phone.toUpperCase().includes(inputValue.toUpperCase())
    : false;
  return labelMatch || phoneMatch;
};

export const renderItem = (value: any) => ({
  key: value.id,
  label: value.name,
  value: value.id,
});

export const getItemOptions = (items: IItem[] = []): IItemOption[] => {
  return items.map((item) => {
    const {
      id,
      dni,
      rtn,
      name,
      type,
      price,
      phone,
      phonePrefix,
      totalNumeration,
      currentNumeration,
      deadlineForIssuance,
    } = item;

    return {
      id,
      key: id,
      label: name || "",
      value: id,
      ...(name && { name }),
      ...(dni && { dni }),
      ...(rtn && { rtn }),
      ...(type && { type }),
      ...(price && { price }),
      ...(totalNumeration && { totalNumeration }),
      ...(currentNumeration && { currentNumeration }),
      ...(deadlineForIssuance && { deadlineForIssuance }),
      ...(phonePrefix && { phonePrefix }),
      ...(phone && { phoneRaw: phone || "" }),
      ...(phone && { phone: `${phonePrefix || ""} ${phone}`.trim() }),
    };
  });
};
