import { useEffect, useState } from "react";
import { getCurrencyFormat } from "@utils/util";
import { connectAndPrint } from "@utils/printer";
import { fetchUsersAsync } from "@store/slices/user";
import { validatePhoneNumber } from "@utils/validation";
import { paymentMethod } from "@constants/paymentMethod";
import { useNavigate, useParams } from "react-router-dom";
import { paymentFormLayout } from "@constants/formLayout";
import { PRIMARY_COLOR } from "@constants/globalConstants";
import { fetchCompaniesAsync } from "@store/slices/company";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { IFormProps, filterData, onFinishFailed } from "@components/Form";
import { getInvoicesExpireMessage } from "@pages/Invoice/helpers/Components";

import {
  fetchReservationAsync,
  clearSelectedReservation,
} from "@store/slices/reservation";

import {
  getSpan,
  optionsRender,
  getModalHeader,
} from "@components/Common/Render";

import {
  clearSelectedInvoices,
  fetchInvoiceConfigurationsAsync,
  createReservationInvoiceAsync,
} from "@store/slices/invoice";

import {
  getRoomPaymentColumns,
  getProductPaymentColumns,
  getServicePaymentColumns,
} from "@components/Reservations/Components";

import {
  onUserChange,
  CalculationOutputs,
  getTotalsCalculation,
  getPendingInvoiceItems,
  shouldDisableSubmitButton,
  getReservationInvoiceObject,
} from "@pages/Invoice/helpers";

import {
  Col,
  Row,
  Form,
  Card,
  Spin,
  Modal,
  Empty,
  Space,
  Input,
  Button,
  Select,
  Divider,
  InputNumber,
} from "antd";

import Table from "antd/es/table";
import IInvoice from "@interfaces/IInvoice";
import FloatButtons from "@components/FloatButtons";
import PhonePrefix from "@components/Common/PhonePrefix";
import IReservationProduct from "@interfaces/IReservationProduct";
import IReservationService from "@interfaces/IReservationService";
import IReservationRoomsPayment from "@interfaces/IReservationRoomsPayment";

const { TextArea } = Input;

const ReservationInvoice: React.FC<IFormProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { guid } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<Boolean>(false);
  const [discountPercentage, setDiscountPercentage] = useState<number>(0);
  const [showPrintInvoiceModal, setShowPrintInvoiceModal] = useState(false);

  const [totalCalculations, setTotalCalculations] =
    useState<CalculationOutputs>({
      total: 0,
      isvTax: 0,
      isrtTax: 0,
      subtotal: 0,
      totalDiscount: 0,
      subtotalWithDiscount: 0,
    });

  const [selectedRoomsPayments, setSelectedRoomsPayments] = useState<
    IReservationRoomsPayment[]
  >([]);
  const [selectedProducts, setSelectedProducts] = useState<
    IReservationProduct[]
  >([]);
  const [selectedServices, setSelectedServices] = useState<
    IReservationService[]
  >([]);

  const { hotel } = useAppSelector((state) => state.hotel);
  const { selectedReservation, loading: selectedReservationLoading } =
    useAppSelector((state) => state.reservation);

  const {
    users,
    userOptions,
    loading: userLoading,
  } = useAppSelector((state) => state.user);

  const {
    companies,
    companyOptions,
    loading: companyLoading,
  } = useAppSelector((state) => state.company);

  const {
    selectedInvoices,
    invoiceConfigurations,
    invoiceConfigurationOptions,
    loading: invoiceLoading,
  } = useAppSelector((state) => state.invoice);

  useEffect(() => {
    setLoading(
      userLoading ||
        invoiceLoading ||
        companyLoading ||
        selectedReservationLoading
    );
  }, [userLoading, invoiceLoading, companyLoading, selectedReservationLoading]);

  useEffect(() => {
    if (users.length === 0) {
      dispatch(fetchUsersAsync());
    }
  }, [dispatch, users]);

  useEffect(() => {
    if (companies.length === 0) {
      dispatch(fetchCompaniesAsync());
    }
  }, [dispatch, companies]);

  useEffect(() => {
    return () => {
      dispatch(clearSelectedInvoices());
      dispatch(clearSelectedReservation());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!selectedReservation && guid) {
      dispatch(fetchReservationAsync(guid));
    } else {
      if (selectedReservation?.company?.id) {
        setSelectedCompany(true);
      }
    }
  }, [dispatch, guid, selectedReservation]);

  useEffect(() => {
    if (invoiceConfigurations.length === 0) {
      dispatch(fetchInvoiceConfigurationsAsync());
    }
  }, [dispatch, invoiceConfigurations]);

  useEffect(() => {
    if (selectedInvoices.length !== 0) {
      setShowPrintInvoiceModal(true);
    }
  }, [dispatch, selectedInvoices]);

  useEffect(() => {
    const totalCalculations = getTotalsCalculation({
      selectedProducts,
      selectedServices,
      discountPercentage,
      selectedRoomsPayments,
    });
    setTotalCalculations(totalCalculations);
  }, [
    selectedProducts,
    selectedServices,
    discountPercentage,
    selectedRoomsPayments,
  ]);

  const onFinish = async (invoiceData: IInvoice) => {
    const newReservationInvoice = getReservationInvoiceObject(
      invoiceData,
      selectedReservation,
      selectedProducts,
      selectedServices,
      selectedRoomsPayments
    );
    await dispatch(createReservationInvoiceAsync(newReservationInvoice));
  };

  const onPrint = async () => {
    await connectAndPrint(selectedInvoices, hotel);
    goBack();
  };

  const goBack = () => {
    setShowPrintInvoiceModal(false);
    props.onBack ? props.onBack() : navigate("..");
  };

  const onFieldsChangeForm = (changedFields: any) => {
    if (changedFields.length !== 1) return;
    const [field] = changedFields;
    const [name] = field.name;
    const index = field.name[1];
    const property = field.name[2];
    if (name === "clients" && property === "id") {
      const user = userOptions?.find((x: any) => x.id === field.value);
      onUserChange(index, form, user);
    }
    if (name === "discountPercentage") {
      setDiscountPercentage(field.value);
    }
    if (name === "companyId") {
      const company =
        companyOptions?.find((x: any) => x.id === field.value) || null;
      form.setFieldValue("companyRtn", company?.rtn || null);
      form.setFieldValue("companyPhone", company?.phoneRaw || null);
      form.setFieldValue("companyPhonePrefix", company?.phonePrefix || null);
      setSelectedCompany(true);
    }
  };

  const pendingProducts = getPendingInvoiceItems(selectedReservation?.products);
  const pendingServices = getPendingInvoiceItems(selectedReservation?.services);
  const pendingRoomsPayments = getPendingInvoiceItems(
    selectedReservation?.roomsPayments
  );

  return (
    <Spin className="spinner" spinning={loading}>
      {selectedReservation && (
        <Card
          bordered={false}
          style={{ width: 1600 }}
          className={props.onBack ? "custom-form-card" : ""}
        >
          <Row>
            <Col span={8}>
              <h2>Detalle de Factura</h2>
              <Divider style={{ marginTop: 0, marginBottom: 10 }} />
              <Form
                form={form}
                onFinish={onFinish}
                {...paymentFormLayout}
                onFinishFailed={onFinishFailed}
                onFieldsChange={onFieldsChangeForm}
              >
                <Card className="custom-card">
                  <Form.Item
                    name="invoiceConfigurationId"
                    rules={[{ required: true }]}
                    label={getSpan("Tipo de Factura")}
                    style={{ width: "95%", marginBottom: 10 }}
                  >
                    <Select
                      size="large"
                      options={invoiceConfigurationOptions}
                    ></Select>
                  </Form.Item>

                  <Form.Item
                    initialValue={0}
                    name="discountPercentage"
                    rules={[{ required: true }]}
                    label={getSpan("Descuento")}
                    style={{ width: "95%", marginBottom: 10 }}
                  >
                    <InputNumber
                      min={0}
                      max={50}
                      size="large"
                      addonAfter="%"
                      controls={true}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Card>
                <Card
                  className="custom-card"
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <Form.Item
                    name="companyId"
                    label={getSpan("Empresa")}
                    initialValue={selectedReservation?.company?.id}
                    style={{ width: "95%", marginBottom: 10 }}
                  >
                    <Select
                      showSearch
                      size="large"
                      options={companyOptions}
                      filterOption={filterData}
                      optionRender={optionsRender}
                      notFoundContent="Empresa no encontrada"
                    ></Select>
                  </Form.Item>
                  {selectedCompany && (
                    <>
                      <Form.Item
                        name={"companyPhone"}
                        label={getSpan("Teléfono")}
                        dependencies={["companyPhonePrefix"]}
                        style={{ width: "95%", marginBottom: 10 }}
                        initialValue={selectedReservation?.company?.phone}
                        rules={[
                          ({ getFieldValue }) => ({
                            async validator(_, value) {
                              const phonePrefix =
                                getFieldValue("companyPhonePrefix");
                              return validatePhoneNumber(_, phonePrefix, value);
                            },
                          }),
                        ]}
                      >
                        <Input
                          size="large"
                          maxLength={20}
                          addonBefore={
                            <PhonePrefix name={"companyPhonePrefix"} />
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name={"companyRtn"}
                        label={getSpan("RTN")}
                        initialValue={selectedReservation?.company?.rtn}
                        style={{ width: "95%", marginBottom: 10 }}
                      >
                        <Input size="large" maxLength={50} />
                      </Form.Item>
                    </>
                  )}
                </Card>
                <Form.Item
                  label={getSpan("Subtotal")}
                  style={{ textAlign: "right", marginBottom: 0 }}
                >
                  {getSpan(
                    `L. ${getCurrencyFormat(totalCalculations.subtotal)}`
                  )}
                </Form.Item>
                <Form.Item
                  label={getSpan("Descuento")}
                  style={{ textAlign: "right", marginBottom: 0 }}
                >
                  {getSpan(
                    `L. ${getCurrencyFormat(totalCalculations.totalDiscount)}`
                  )}
                </Form.Item>
                <Form.Item
                  label={getSpan("Subtotal con Descuento")}
                  style={{ textAlign: "right", marginBottom: 0 }}
                >
                  {getSpan(
                    `L. ${getCurrencyFormat(
                      totalCalculations.subtotalWithDiscount
                    )}`
                  )}
                </Form.Item>
                <Form.Item
                  label={getSpan("I.S.V.")}
                  style={{ textAlign: "right", marginBottom: 0 }}
                >
                  {getSpan(`L. ${getCurrencyFormat(totalCalculations.isvTax)}`)}
                </Form.Item>
                <Form.Item
                  label={getSpan("I.S.R.T.")}
                  style={{ textAlign: "right", marginBottom: 0 }}
                >
                  {getSpan(
                    `L. ${getCurrencyFormat(totalCalculations.isrtTax)}`
                  )}
                </Form.Item>
                <Form.Item
                  label={getSpan("Total")}
                  style={{ textAlign: "right", marginBottom: 10 }}
                >
                  {getSpan(`L. ${getCurrencyFormat(totalCalculations.total)}`)}
                </Form.Item>
                {getSpan("Notas")}
                <Form.Item noStyle name="invoiceNotes">
                  <TextArea
                    rows={5}
                    maxLength={200}
                    style={{ maxWidth: "100%" }}
                  />
                </Form.Item>
                <Form.Item noStyle>
                  <Space size="large" style={{ marginTop: 20 }}>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      disabled={
                        invoiceLoading ||
                        shouldDisableSubmitButton(
                          selectedProducts,
                          selectedServices,
                          selectedRoomsPayments
                        )
                      }
                    >
                      Crear Factura
                    </Button>
                    <Button size="large" onClick={() => goBack()}>
                      Cancelar
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
            <Col span={8} style={{ paddingLeft: 30, paddingRight: 30 }}>
              <h2>Clientes</h2>
              <Form
                form={form}
                onFinish={onFinish}
                {...paymentFormLayout}
                onFinishFailed={onFinishFailed}
                onFieldsChange={onFieldsChangeForm}
              >
                <Form.List
                  name="clients"
                  initialValue={[
                    {
                      id: selectedReservation?.user?.id,
                      name: selectedReservation.user?.name,
                      dni: selectedReservation.user?.dni,
                      rtn: selectedReservation.user?.rtn,
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      <Divider style={{ marginTop: 0, marginBottom: 10 }} />
                      {fields.map(({ key, name, ...restField }) => (
                        <Form.Item key={key} noStyle>
                          <Row>
                            <Col span={11}>
                              <div>{getSpan("Cliente")}</div>
                              <Form.Item
                                {...restField}
                                noStyle
                                name={[name, "id"]}
                                rules={[{ required: true }]}
                              >
                                <Select
                                  showSearch
                                  size="large"
                                  options={userOptions}
                                  style={{ width: "90%" }}
                                  filterOption={filterData}
                                  optionRender={optionsRender}
                                  notFoundContent="Usuario no encontrado"
                                ></Select>
                              </Form.Item>
                            </Col>
                            <Col span={11}>
                              <div>{getSpan("Método de pago")}</div>
                              <Form.Item
                                {...restField}
                                noStyle
                                rules={[{ required: true }]}
                                name={[name, "paymentMethod"]}
                                initialValue={paymentMethod.CASH}
                              >
                                <Select size="large" style={{ width: "90%" }}>
                                  <Select.Option value={paymentMethod.CASH}>
                                    {paymentMethod.CASH}
                                  </Select.Option>
                                  <Select.Option value={paymentMethod.TRANSFER}>
                                    {paymentMethod.TRANSFER}
                                  </Select.Option>
                                  <Select.Option
                                    value={paymentMethod.CREDIT_CARD}
                                  >
                                    {paymentMethod.CREDIT_CARD}
                                  </Select.Option>
                                  <Select.Option value={paymentMethod.CREDIT}>
                                    {paymentMethod.CREDIT}
                                  </Select.Option>
                                  <Select.Option
                                    value={paymentMethod.PAYMENT_LINK}
                                  >
                                    {paymentMethod.PAYMENT_LINK}
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: 10 }}>
                            <Col span={11}>
                              <div>{getSpan("DNI")}</div>
                              <Form.Item
                                {...restField}
                                noStyle
                                name={[name, "dni"]}
                              >
                                <Input
                                  size="large"
                                  maxLength={50}
                                  style={{ width: "90%" }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={11}>
                              <div>{getSpan("RTN")}</div>
                              <Form.Item
                                {...restField}
                                noStyle
                                name={[name, "rtn"]}
                                label={getSpan("RTN")}
                              >
                                <Input
                                  size="large"
                                  maxLength={50}
                                  style={{ width: "90%" }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={1} style={{ marginTop: 30 }}>
                              <MinusCircleOutlined
                                style={{ marginBottom: 20 }}
                                onClick={() => remove(name)}
                                className="dynamic-delete-button"
                              />
                            </Col>
                          </Row>
                          <Divider
                            style={{ marginTop: 10, marginBottom: 10 }}
                          />
                        </Form.Item>
                      ))}
                      {fields.length < 4 ? (
                        <Row style={{ marginTop: 10 }}>
                          <Col span={24}>
                            <Form.Item noStyle>
                              <Button
                                block
                                type="dashed"
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                                style={{
                                  color: PRIMARY_COLOR,
                                  borderColor: PRIMARY_COLOR,
                                }}
                              >
                                Agregar cliente
                              </Button>
                              <Form.ErrorList errors={errors} />
                            </Form.Item>
                          </Col>
                        </Row>
                      ) : null}
                    </>
                  )}
                </Form.List>
              </Form>
            </Col>
            <Col span={8} style={{ paddingLeft: 0 }}>
              <h2>Pagos Pendientes</h2>
              <Divider style={{ marginTop: 0, marginBottom: 0 }} />
              <Row>
                <Col span={24}>
                  <h3>{getSpan("Habitaciones")}</h3>
                  {pendingRoomsPayments.length > 0 ? (
                    <Table
                      loading={loading}
                      pagination={false}
                      rowKey={(x) => `${x.id}`}
                      columns={getRoomPaymentColumns}
                      dataSource={pendingRoomsPayments}
                      rowSelection={{
                        onChange: (_: any, selectedRows: any[]) => {
                          setSelectedRoomsPayments(selectedRows);
                        },
                      }}
                    />
                  ) : (
                    <Empty description="No hay habitaciones" />
                  )}
                </Col>
              </Row>
              <br />
              <Row>
                <Col span={24}>
                  <h3>{getSpan("Productos")}</h3>
                  {pendingProducts.length > 0 ? (
                    <Table
                      loading={loading}
                      pagination={false}
                      rowKey={(x) => `${x.id}`}
                      dataSource={pendingProducts}
                      columns={getProductPaymentColumns}
                      rowSelection={{
                        onChange: (_: any, selectedRows: any[]) => {
                          setSelectedProducts(selectedRows);
                        },
                      }}
                    />
                  ) : (
                    <Empty description="No hay productos" />
                  )}
                </Col>
              </Row>
              <br />
              <Row>
                <Col span={24}>
                  <h3>{getSpan("Servicios")}</h3>
                  {pendingServices.length > 0 ? (
                    <Table
                      loading={loading}
                      pagination={false}
                      rowKey={(x) => `${x.id}`}
                      dataSource={pendingServices}
                      columns={getServicePaymentColumns}
                      rowSelection={{
                        onChange: (_: any, selectedRows: any[]) => {
                          setSelectedServices(selectedRows);
                        },
                      }}
                    />
                  ) : (
                    <Empty description="No hay servicios" />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      )}
      {showPrintInvoiceModal && (
        <Modal
          centered
          cancelText="Regresar"
          onOk={() => onPrint()}
          okText="Imprimir Factura"
          onCancel={() => goBack()}
          open={showPrintInvoiceModal}
          title={getModalHeader("Desea imprimir la factura?")}
        >
          {getInvoicesExpireMessage(invoiceConfigurationOptions)}
        </Modal>
      )}
      <FloatButtons />
    </Spin>
  );
};

export default ReservationInvoice;
