import ITimeline from "@interfaces/ITimeline";

import { toastHandler } from "@utils/toastHandler";
import { getErrorMessage } from "@utils/errorMessage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  clearActiveReservations,
  clearSelectedReservation,
} from "../reservation";

import {
  getTimelineService,
  updateTimelineReservationService,
} from "@services/timelineService";

export interface TimelineState {
  loading: boolean;
  timeline: ITimeline | null;
}

const initialState: TimelineState = {
  timeline: null,
  loading: false,
};

export const fetchTimelineAsync = createAsyncThunk(
  "timeline/fetchTimeline",
  async (date: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(clearSelectedReservation());
      return await getTimelineService(date);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateTimelineReservationAsync = createAsyncThunk(
  "timeline/updateTimelineReservation",
  async (updatedTimelineReservation: any, { dispatch, rejectWithValue }) => {
    try {
      dispatch(clearActiveReservations());
      return await updateTimelineReservationService(updatedTimelineReservation);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const timelineSlice = createSlice({
  name: "timeline",
  initialState,
  reducers: {
    clearTimeline: (state) => {
      state.timeline = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTimelineAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTimelineAsync.fulfilled, (state, action) => {
        state.timeline = action.payload;
        state.loading = false;
      })
      .addCase(fetchTimelineAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(updateTimelineReservationAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTimelineReservationAsync.fulfilled, (state) => {
        state.timeline = null;
        state.loading = false;
        toastHandler.success("Reservación actualizada exitosamente");
      })
      .addCase(updateTimelineReservationAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      });
  },
});

export const { clearTimeline } = timelineSlice.actions;
export default timelineSlice.reducer;
