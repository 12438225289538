import IService from "@interfaces/IService";

import { toastHandler } from "@utils/toastHandler";
import { getErrorMessage } from "@utils/errorMessage";
import { getItemOptions, IItemOption } from "@components/Form";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getServicesService,
  createServiceService,
  updateServiceService,
  getServiceService,
} from "@services/serviceService";

export interface ServiceState {
  loading: boolean;
  services: IService[];
  serviceOptions: IItemOption[];
  selectedService: IService | null;
}

const initialState: ServiceState = {
  services: [],
  loading: false,
  serviceOptions: [],
  selectedService: null,
};

export const fetchServiceAsync = createAsyncThunk(
  "service/fetchService",
  async (id: string, { rejectWithValue }) => {
    try {
      return await getServiceService(id);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const fetchServicesAsync = createAsyncThunk(
  "service/fetchServices",
  async (_, { rejectWithValue }) => {
    try {
      return await getServicesService();
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const createServiceAsync = createAsyncThunk(
  "service/createService",
  async (newService: IService, { rejectWithValue }) => {
    try {
      return await createServiceService(newService);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateServiceAsync = createAsyncThunk(
  "service/updateService",
  async (updatedService: IService, { rejectWithValue }) => {
    try {
      return await updateServiceService(updatedService);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    clearSelectedService: (state) => {
      state.selectedService = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchServiceAsync.fulfilled, (state, action) => {
        state.selectedService = action.payload;
        state.loading = false;
      })
      .addCase(fetchServiceAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(fetchServicesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchServicesAsync.fulfilled, (state, action) => {
        state.services = action.payload;
        state.serviceOptions = getItemOptions(action.payload);
        state.loading = false;
      })
      .addCase(fetchServicesAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(createServiceAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createServiceAsync.fulfilled, (state, action) => {
        state.services = action.payload;
        state.serviceOptions = getItemOptions(action.payload);
        state.loading = false;
        toastHandler.success("Servicio creado!");
      })
      .addCase(createServiceAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(updateServiceAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateServiceAsync.fulfilled, (state, action) => {
        state.services = action.payload;
        state.serviceOptions = getItemOptions(action.payload);
        state.loading = false;
        toastHandler.success("Servicio actualizado!");
      })
      .addCase(updateServiceAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      });
  },
});

export const { clearSelectedService } = serviceSlice.actions;
export default serviceSlice.reducer;
