import IRoom from "@interfaces/IRoom";

import { toastHandler } from "@utils/toastHandler";
import { getErrorMessage } from "@utils/errorMessage";
import { getItemOptions, IItemOption } from "@components/Form";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getRoomService,
  getRoomsService,
  createRoomService,
  deleteRoomService,
  updateRoomService,
} from "@services/roomService";

export interface RoomState {
  rooms: IRoom[];
  loading: boolean;
  roomOptions: IItemOption[];
  selectedRoom: IRoom | null;
}

const initialState: RoomState = {
  rooms: [],
  loading: false,
  roomOptions: [],
  selectedRoom: null,
};

export const fetchRoomAsync = createAsyncThunk(
  "room/fetchRoom",
  async (id: string, { rejectWithValue }) => {
    try {
      return await getRoomService(id);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const fetchRoomsAsync = createAsyncThunk(
  "room/fetchRooms",
  async (_, { rejectWithValue }) => {
    try {
      return await getRoomsService();
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const createRoomAsync = createAsyncThunk(
  "room/createRoom",
  async (newRoom: IRoom, { rejectWithValue }) => {
    try {
      return await createRoomService(newRoom);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateRoomAsync = createAsyncThunk(
  "room/updateRoom",
  async (updatedRoom: IRoom, { rejectWithValue }) => {
    try {
      return await updateRoomService(updatedRoom);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const deleteRoomAsync = createAsyncThunk(
  "room/deleteRoom",
  async (id: string, { rejectWithValue }) => {
    try {
      return await deleteRoomService(id);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const roomSlice = createSlice({
  name: "room",
  initialState,
  reducers: {
    clearSelectedRoom: (state) => {
      state.selectedRoom = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoomAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRoomAsync.fulfilled, (state, action) => {
        state.selectedRoom = action.payload;
        state.loading = false;
      })
      .addCase(fetchRoomAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(fetchRoomsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRoomsAsync.fulfilled, (state, action) => {
        state.rooms = action.payload;
        state.roomOptions = getItemOptions(action.payload);
        state.loading = false;
      })
      .addCase(fetchRoomsAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(createRoomAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createRoomAsync.fulfilled, (state, action) => {
        state.rooms = action.payload;
        state.roomOptions = getItemOptions(action.payload);
        state.loading = false;
        toastHandler.success("Habitación creada!");
      })
      .addCase(createRoomAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(updateRoomAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateRoomAsync.fulfilled, (state, action) => {
        state.rooms = action.payload;
        state.roomOptions = getItemOptions(action.payload);
        state.loading = false;
        toastHandler.success("Habitación actualizada!");
      })
      .addCase(updateRoomAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(deleteRoomAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRoomAsync.fulfilled, (state, action) => {
        state.rooms = action.payload;
        state.roomOptions = getItemOptions(action.payload);
        state.loading = false;
        toastHandler.success("Habitación borrada exitosamente!");
      })
      .addCase(deleteRoomAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      });
  },
});

export const { clearSelectedRoom } = roomSlice.actions;
export default roomSlice.reducer;
