import { Moment } from "moment";
import { clearUsers } from "../user";
import { clearCompanies } from "../company";
import { IItemOption } from "@components/Form";
import { toastHandler } from "@utils/toastHandler";
import { getErrorMessage } from "@utils/errorMessage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getInvoiceConfigurationsOptions } from "@pages/Invoice/helpers";

import {
  getInvoicesService,
  deleteInvoiceService,
  createInvoiceService,
  createReservationInvoiceService,
} from "@services/invoiceService";

import {
  getInvoiceConfigurationService,
  getInvoiceConfigurationsService,
  createInvoiceConfigurationService,
} from "@services/invoiceConfigurationService";

import IInvoice from "@interfaces/IInvoice";
import IInvoiceConfiguration from "@interfaces/IInvoiceConfiguration";

interface InvoiceConfigurationState {
  loading: boolean;
  invoices: IInvoice[];
  selectedInvoices: IInvoice[];
  invoiceConfigurationOptions: IItemOption[];
  invoiceConfigurations: IInvoiceConfiguration[];
  selectedInvoiceConfiguration: IInvoiceConfiguration | null;
}

const initialState: InvoiceConfigurationState = {
  invoices: [],
  loading: false,
  selectedInvoices: [],
  invoiceConfigurations: [],
  invoiceConfigurationOptions: [],
  selectedInvoiceConfiguration: null,
};

export const fetchInvoicesAsync = createAsyncThunk(
  "invoice/fetchInvoicesAsync",
  async (date: Moment, { rejectWithValue }) => {
    try {
      return await getInvoicesService(date);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const createInvoiceAsync = createAsyncThunk(
  "room/createInvoice",
  async (newInvoice: IInvoice, { dispatch, rejectWithValue }) => {
    try {
      const response = await createInvoiceService(newInvoice);
      dispatch(clearUsers());
      dispatch(clearCompanies());
      return response;
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const createReservationInvoiceAsync = createAsyncThunk(
  "room/createReservationInvoice",
  async (newInvoice: IInvoice, { dispatch, rejectWithValue }) => {
    try {
      const response = await createReservationInvoiceService(newInvoice);
      dispatch(clearUsers());
      dispatch(clearCompanies());
      return response;
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const deleteInvoiceAsync = createAsyncThunk(
  "room/deleteInvoice",
  async (id: string, { rejectWithValue }) => {
    try {
      return await deleteInvoiceService(id);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const fetchInvoiceConfigurationAsync = createAsyncThunk(
  "invoice/fetchInvoiceConfiguration",
  async (id: string, { rejectWithValue }) => {
    try {
      return await getInvoiceConfigurationService(id);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const fetchInvoiceConfigurationsAsync = createAsyncThunk(
  "invoice/fetchInvoiceConfigurations",
  async (_, { rejectWithValue }) => {
    try {
      return await getInvoiceConfigurationsService();
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const createInvoiceConfigurationAsync = createAsyncThunk(
  "invoice/createInvoiceConfiguration",
  async (
    newInvoiceConfiguration: IInvoiceConfiguration,
    { rejectWithValue }
  ) => {
    try {
      return await createInvoiceConfigurationService(newInvoiceConfiguration);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    clearSelectedInvoices: (state) => {
      state.selectedInvoices = [];
    },
    clearSelectedInvoiceConfiguration: (state) => {
      state.selectedInvoiceConfiguration = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoicesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInvoicesAsync.fulfilled, (state, action) => {
        state.invoices = action.payload;
        state.loading = false;
      })
      .addCase(fetchInvoicesAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(createInvoiceAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createInvoiceAsync.fulfilled, (state, action) => {
        state.invoices = [];
        state.selectedInvoices = action.payload;
        state.loading = false;
        toastHandler.success("Factura creada!");
      })
      .addCase(createInvoiceAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(createReservationInvoiceAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createReservationInvoiceAsync.fulfilled, (state, action) => {
        state.invoices = [];
        state.selectedInvoices = action.payload;
        state.loading = false;
        toastHandler.success("Factura creada!");
      })
      .addCase(createReservationInvoiceAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(deleteInvoiceAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteInvoiceAsync.fulfilled, (state) => {
        state.invoices = [];
        state.loading = false;
        toastHandler.success("Factura anulada exitosamente!");
      })
      .addCase(deleteInvoiceAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(fetchInvoiceConfigurationAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInvoiceConfigurationAsync.fulfilled, (state, action) => {
        state.selectedInvoiceConfiguration = action.payload;
        state.loading = false;
      })
      .addCase(fetchInvoiceConfigurationAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(fetchInvoiceConfigurationsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInvoiceConfigurationsAsync.fulfilled, (state, action) => {
        state.invoiceConfigurations = action.payload;
        state.invoiceConfigurationOptions = getInvoiceConfigurationsOptions(
          action.payload
        );
        state.loading = false;
      })
      .addCase(fetchInvoiceConfigurationsAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(createInvoiceConfigurationAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createInvoiceConfigurationAsync.fulfilled, (state, action) => {
        state.invoiceConfigurations = action.payload;
        state.invoiceConfigurationOptions = getInvoiceConfigurationsOptions(
          action.payload
        );
        state.loading = false;
        toastHandler.success("Configuracion de facturación creado!");
      })
      .addCase(createInvoiceConfigurationAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      });
  },
});

export const { clearSelectedInvoices, clearSelectedInvoiceConfiguration } =
  invoiceSlice.actions;
export default invoiceSlice.reducer;

