import { FormInstance } from "antd";
import { getItemOptions, IItem, IItemOption } from "@components/Form";
import { isInvoiceDateValid, isInvoiceDateAboutToExpire } from "@utils/date";

import IInvoice from "@interfaces/IInvoice";
import IReservation from "@interfaces/IReservation";
import IReservationProduct from "@interfaces/IReservationProduct";
import IReservationService from "@interfaces/IReservationService";
import IReservationRoomsPayment from "@interfaces/IReservationRoomsPayment";
import IUser from "@interfaces/IUser";
import IReservationRoom from "@interfaces/IReservationRoom";

export interface CalculationInputs {
  discountPercentage: number;
  selectedProducts: IReservationProduct[];
  selectedServices: IReservationService[];
  selectedRoomsPayments: IReservationRoomsPayment[];
}

export interface CalculationOutputs {
  total: number;
  isvTax: number;
  isrtTax: number;
  subtotal: number;
  totalDiscount: number;
  subtotalWithDiscount: number;
}

export const onRoomChange = (index: number, form: any, room: IItemOption) => {
  const roomsField = form.getFieldValue("rooms");
  roomsField[index].name = room.name || null;
  roomsField[index].type = room.type || null;
  roomsField[index].price = room.price || 0;
  form.setFieldValue("rooms", roomsField);
};

export const onProductChange = (
  index: number,
  form: any,
  product: IItemOption
) => {
  const productsField = form.getFieldValue("products");
  productsField[index].name = product.name || null;
  productsField[index].price = product.price || 0;
  form.setFieldValue("products", productsField);
};

export const onRoomTypeChange = (
  index: number,
  form: any,
  room: IItemOption
) => {
  const roomsField = form.getFieldValue("rooms");
  roomsField[index].price = room.price || 0;
  form.setFieldValue("rooms", roomsField);
};

export const onServiceChange = (
  index: number,
  form: any,
  service: IItemOption
) => {
  const servicesField = form.getFieldValue("services");
  servicesField[index].name = service.name || null;
  servicesField[index].price = service.price || 0;
  form.setFieldValue("services", servicesField);
};

export const onUserChange = (index: number, form: FormInstance, user: any) => {
  const usersField = form.getFieldValue("clients");
  usersField[index].name = user?.name || null;
  usersField[index].dni = user?.dni || null;
  usersField[index].rtn = user?.rtn || null;
  form.setFieldValue("clients", usersField);
};

export const calculateSubtotal = (items: any[], priceMultiplier: number = 1) =>
  items?.reduce((accumulator, item) => {
    return (
      accumulator +
      Number(item.price) * Number(item.quantity || 1) * priceMultiplier
    );
  }, 0);

export const getTotalsCalculation = ({
  selectedProducts,
  selectedServices,
  discountPercentage,
  selectedRoomsPayments,
}: CalculationInputs): CalculationOutputs => {
  const calculateDiscount = (subtotal: number) =>
    (subtotal * discountPercentage) / 100 || 0;

  const calculateSubtotals = (items: any[]) => {
    const subtotal = calculateSubtotal(items);
    const discount = calculateDiscount(subtotal);
    const subtotalWithDiscount = subtotal - discount;

    return { subtotal, discount, subtotalWithDiscount };
  };

  const roomPayments = calculateSubtotals(selectedRoomsPayments);
  const products = calculateSubtotals(selectedProducts);
  const services = calculateSubtotals(selectedServices);

  const totalDiscount =
    roomPayments.discount + products.discount + services.discount;
  const subtotal =
    roomPayments.subtotal + products.subtotal + services.subtotal;
  const subtotalWithDiscount =
    roomPayments.subtotalWithDiscount +
    products.subtotalWithDiscount +
    services.subtotalWithDiscount;

  const isvTax = subtotalWithDiscount * 0.15;
  const isrtTax = roomPayments.subtotalWithDiscount * 0.04;
  const total = subtotalWithDiscount + isvTax + isrtTax;

  return {
    total,
    isvTax,
    isrtTax,
    subtotal,
    totalDiscount,
    subtotalWithDiscount,
  };
};

export const getItemIds = (items: any) => {
  return items?.map((x: any) => x.id);
};

export const getItems = (allFields: any, property: string) =>
  allFields
    ?.find((x: any) => x.name.length === 1 && x.name[0] === property)
    ?.value?.filter((x: any) => x) || [];

export const getPendingInvoiceItems = (items: any) => {
  return items?.filter((x: any) => !x.invoiceIds);
};

export const getValidInvoiceConfigurations = (items: IItem[]) =>
  items?.filter((x: IItem) => {
    const invoiceNumbers =
      Number(x.totalNumeration) - Number(x.currentNumeration);
    const limitDate = isInvoiceDateValid(x.deadlineForIssuance || "");
    return invoiceNumbers >= 0 && limitDate;
  });

export const getInvoiceConfigurationsAboutToExpire = (items: IItemOption[]) =>
  items.filter((x: IItemOption) => {
    const invoiceNumbers =
      Number(x.totalNumeration) - Number(x.currentNumeration);
    const limitDate = isInvoiceDateAboutToExpire(x.deadlineForIssuance || "");
    return invoiceNumbers <= 50 || limitDate;
  });

export const getInvoiceConfigurationsOptions = (
  items: IItem[]
): IItemOption[] => {
  const invoiceConfigurations = getValidInvoiceConfigurations(items);
  return getItemOptions(invoiceConfigurations);
};

export const getReservationInvoiceObject = (
  invoiceData: IInvoice,
  reservation: IReservation | null,
  products: IReservationProduct[],
  services: IReservationService[],
  roomsPayments: IReservationRoomsPayment[]
) => {
  const reservationProductsIds = getItemIds(products);
  const reservationServicesIds = getItemIds(services);
  const reservationRoomsPaymentsIds = getItemIds(roomsPayments);
  return Object.assign({}, invoiceData, {
    reservationId: reservation?.id,
    reservationProductsIds,
    reservationServicesIds,
    reservationRoomsPaymentsIds,
  });
};

export const shouldDisableSubmitButton = (
  selectedProducts: IReservationProduct[],
  selectedServices: IReservationService[],
  selectedRoomPayments: IReservationRoomsPayment[]
) =>
  selectedProducts.length === 0 &&
  selectedServices.length === 0 &&
  selectedRoomPayments.length === 0;

export const shouldDisableInvoiceSubmitButton = (
  selectedProducts: IReservationProduct[],
  selectedServices: IReservationService[],
  selectedClients: IUser[]
) =>
  selectedClients.length === 0 ||
  (selectedProducts.length === 0 && selectedServices.length === 0);

export const shouldDisableQuoteSubmitButton = (
  selectedRooms: IReservationRoom[],
  selectedProducts: IReservationProduct[],
  selectedServices: IReservationService[]
) =>
  selectedRooms.length === 0 &&
  selectedProducts.length === 0 &&
  selectedServices.length === 0;
