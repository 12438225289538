import { ROLE } from "@constants/role";
import { isAuthUser } from "@utils/util";
import { fetchHotelAsync } from "../hotel";
import { toastHandler } from "@utils/toastHandler";
import { HOTEL_SLUG } from "@constants/globalConstants";
import { getFirebaseMessage } from "@utils/errorMessage";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  getAuth,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";

interface AuthState {
  isAdmin: boolean;
  loading: boolean;
  user: {
    uid: string;
    email: string | null;
    displayName: string | null;
    photoURL: string | null;
    emailVerified: boolean;
  } | null;
}

interface ICredentials {
  username: string;
  password: string;
}

const initialState: AuthState = {
  user: null,
  isAdmin: false,
  loading: false,
};

export const doLoginAsync = createAsyncThunk(
  "user/doLogin",
  async ({ username, password }: ICredentials, { rejectWithValue }) => {
    const auth = getAuth();
    try {
      const { user } = await signInWithEmailAndPassword(
        auth,
        username,
        password
      );
      const serializedUser = {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        emailVerified: user.emailVerified,
      };
      return serializedUser;
    } catch (error: any) {
      return rejectWithValue(getFirebaseMessage(error));
    }
  }
);

export const doLogoutAsync = createAsyncThunk(
  "user/doLogout",
  async (_, { rejectWithValue }) => {
    const auth = getAuth();
    try {
      return await signOut(auth);
    } catch (error: any) {
      return rejectWithValue(getFirebaseMessage(error));
    }
  }
);

export const doResetPasswordAsync = createAsyncThunk(
  "user/doResetPassword",
  async (email: string, { rejectWithValue }) => {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      return true;
    } catch (error: any) {
      return rejectWithValue(getFirebaseMessage(error));
    }
  }
);

export const doInitializeAuthAsync = createAsyncThunk<
  any | null,
  void,
  { rejectValue: string }
>("auth/doInitializeAuth", async (_, { dispatch, rejectWithValue }) => {
  const auth = getAuth();
  try {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(new Error("Timeout initializing auth"));
      }, 5000);

      onAuthStateChanged(auth, async (user) => {
        clearTimeout(timeout);
        try {
          if (user) {
            const token = await user.getIdTokenResult();
            if (isAuthUser(token)) {
              dispatch(doLogoutAsync());
              toastHandler.error("Permisos invalidos");
              resolve(null);
            } else {
              const isAdmin = token.claims.role === ROLE.ADMIN;
              const serializedUser = {
                uid: user.uid,
                email: user.email,
                displayName: user.displayName,
                photoURL: user.photoURL,
                emailVerified: user.emailVerified,
              };
              await dispatch(setIsAdmin(isAdmin));
              await dispatch(fetchHotelAsync(HOTEL_SLUG || ""));
              resolve(serializedUser);
            }
          } else {
            dispatch(doLogoutAsync());
            resolve(null);
          }
        } catch (error: any) {
          reject(error);
        }
      });
    });
  } catch (error: any) {
    return rejectWithValue(getFirebaseMessage(error));
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doLoginAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(doLoginAsync.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(doLoginAsync.rejected, (state, action) => {
        toastHandler.error(action.payload as string);
        state.loading = false;
      })
      .addCase(doLogoutAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(doLogoutAsync.fulfilled, (state, action) => {
        state.user = null;
        state.isAdmin = false;
        state.loading = false;
      })
      .addCase(doLogoutAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(doInitializeAuthAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(doInitializeAuthAsync.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(doInitializeAuthAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(doResetPasswordAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(doResetPasswordAsync.fulfilled, (state) => {
        state.loading = false;
        toastHandler.success("Se envío un correo para resetear la contraseña");
      })
      .addCase(doResetPasswordAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      });
  },
});

export const { setIsAdmin } = authSlice.actions;
export default authSlice.reducer;
