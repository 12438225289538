import { toastHandler } from "@utils/toastHandler";
import { getErrorMessage } from "@utils/errorMessage";
import { getItemOptions, IItemOption } from "@components/Form";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getCompanyService,
  getCompaniesService,
  createCompanyService,
  deleteCompanyService,
  updateCompanyService,
} from "@services/companyService";

import ICompany from "@interfaces/ICompany";

export interface CompanyState {
  loading: boolean;
  companies: ICompany[];
  companyOptions: IItemOption[];
  selectedCompany: ICompany | null;
}

const initialState: CompanyState = {
  companies: [],
  loading: false,
  companyOptions: [],
  selectedCompany: null,
};

export const fetchCompanyAsync = createAsyncThunk(
  "user/fetchCompany",
  async (id: string, { rejectWithValue }) => {
    try {
      return await getCompanyService(id);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const fetchCompaniesAsync = createAsyncThunk(
  "companies/fetchCompanies",
  async (_, { rejectWithValue }) => {
    try {
      return await getCompaniesService();
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const createCompanyAsync = createAsyncThunk(
  "companies/createCompany",
  async (newCompany: ICompany, { rejectWithValue }) => {
    try {
      return await createCompanyService(newCompany);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateCompanyAsync = createAsyncThunk(
  "companies/updateCompany",
  async (updatedCompany: ICompany, { rejectWithValue }) => {
    try {
      return await updateCompanyService(updatedCompany);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const deleteCompanyAsync = createAsyncThunk(
  "companies/deleteCompany",
  async (id: string, { rejectWithValue }) => {
    try {
      return await deleteCompanyService(id);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    clearCompanies: (state) => {
      state.companies = [];
      state.companyOptions = [];
    },
    clearSelectedCompany: (state) => {
      state.selectedCompany = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCompanyAsync.fulfilled, (state, action) => {
        state.selectedCompany = action.payload;
        state.loading = false;
      })
      .addCase(fetchCompanyAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(fetchCompaniesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCompaniesAsync.fulfilled, (state, action) => {
        state.companies = action.payload;
        state.companyOptions = getItemOptions(action.payload);
        state.loading = false;
      })
      .addCase(fetchCompaniesAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(createCompanyAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCompanyAsync.fulfilled, (state, action) => {
        state.companies = action.payload;
        state.companyOptions = getItemOptions(action.payload);
        state.loading = false;
        toastHandler.success("Empresa creada!");
      })
      .addCase(createCompanyAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(updateCompanyAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCompanyAsync.fulfilled, (state, action) => {
        state.companies = action.payload;
        state.companyOptions = getItemOptions(action.payload);
        state.loading = false;
        toastHandler.success("Empresa actualizada!");
      })
      .addCase(updateCompanyAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(deleteCompanyAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCompanyAsync.fulfilled, (state, action) => {
        state.companies = action.payload;
        state.companyOptions = getItemOptions(action.payload);
        state.loading = false;
        toastHandler.success("Empresa borrada exitosamente!");
      })
      .addCase(deleteCompanyAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      });
  },
});

export const { clearCompanies, clearSelectedCompany } = companySlice.actions;
export default companySlice.reducer;
